import { Label } from "api/labels/models";
import { CURRENCY_TYPE, CountryCode } from "CONSTANTS";
import { OrderPaymentType, Status } from "../orders/models";
import { UserWithInitialsExtendedDetails } from "api/users/models";
import { BasePaymentType, ISODateTime, UUID } from "api/types";
import { Assign } from "utility-types";

export interface Address {
  id: number;
  name: string;
  postCode: string;
  city: string;
  countryCode: CountryCode;
  street: string;
  point: { lat: number; lng: number };
  isDefault: boolean;
  customer: number;
  phone: string;
  externalAddressNumber: string;
}

export type ViesValidStatus = "ACTIVE" | "INACTIVE" | "UNREGISTERED";

export enum RecurringInvoiceCreationStrategy {
  NONE = "NONE",
  ON_GIVEN_DAY = "ON_GIVEN_DAY",
  EVERY_X_DAYS = "EVERY_X_DAYS",
}

export const recurringInvoiceCreationStrategy: {
  id: RecurringInvoiceCreationStrategy;
  name: string;
}[] = [
  { id: RecurringInvoiceCreationStrategy.NONE, name: "brak" },
  { id: RecurringInvoiceCreationStrategy.ON_GIVEN_DAY, name: "danego dnia miesiąca" },
  { id: RecurringInvoiceCreationStrategy.EVERY_X_DAYS, name: "co X dni" },
];

export interface Customer {
  id: number;
  businessEntity: {
    id: UUID;
  } | null;
  canSendSms: boolean;
  fullName: string;
  name: string;
  defaultPaymentType: BasePaymentType;
  created: ISODateTime;
  taxId: string;
  postCode: string;
  ownerFirstName: string;
  defaultCurrency: CURRENCY_TYPE;
  ownerLastName: string;
  salesAccount: number | null;
  city: string;
  color: string;
  textColor: string;
  defaultCollectionDateDays: number;
  hasDropShipping: boolean;
  canAddNewIndexes: boolean;
  hasPermissionsToCreateProducts: boolean;
  hasAmountToCollect: boolean;
  street: string;
  businessRegisterNumber: string;
  discount: string;
  phone: string;
  email: string;
  website: string;
  isConnected: boolean;
  integrationToken: string;
  url: string;
  addresses: Address[];
  productSet: { id: number; name: string } | null;
  priceList: { id: number; name: string } | null;
  hasPermissionsToEditWarehouseDeliveryDate: boolean;
  maximumDeliveryToWarehouseTime: number;
  isRecipient: boolean;
  isVisibleOnLabel: boolean;
  labelTemplate: Omit<Label, "createdAt"> | null;
  logo: string | null;
  lastOrders: {
    id: number;
    signature: number;
    status: Status;
    created: string;
    price: { amount: number; currency: string };
  }[];
  countryCode: string;
  invoiceCreationDay?: number;
  invoiceCreationEveryXDays?: number;
  recurringInvoiceCreationStrategy?: RecurringInvoiceCreationStrategy;
  viesCheckedAt: string;
  viesValidStatus: ViesValidStatus;
  accountBalance: {
    amount: string;
    currency: CURRENCY_TYPE;
  } | null;
  amountToPay: {
    amount: string;
    currency: CURRENCY_TYPE;
  } | null;
  paidAmount: {
    amount: string;
    currency: CURRENCY_TYPE;
  } | null;
}

export const viesWhiteListStatus: Record<ViesValidStatus, string> = {
  ACTIVE: "Aktywny",
  INACTIVE: "Nieaktywny",
  UNREGISTERED: "Niezarejestrowany",
};
export interface AuthenticatedUserCustomerAddress {
  id: number;
  name: string;
  postCode: string;
  city: string;
  countryCode: string;
  street: string;
  externalAddressNumber: string;
  point: { lat: number; lng: number };
  isDefault: boolean;
  phone: string;
}

export interface AuthenticatedUserCustomerDetails {
  id: number;
  name: string;
  postCode: string;
  street: string;
  taxId: string;
  city: string;
  customer: {
    canAddNewIndexes: boolean;
    canSendSms: boolean;
    defaultCollectionDateDays: number;
    email: string;
    hasPermissionsToCreateProducts: boolean;
    hasPermissionsToEditWarehouseDeliveryDate: boolean;
    id: number;
    name: string;
    phone: string;
    productSet: number;
    salesAccount: number;
  };
  hasDropShipping: boolean;
  canAddNewIndexes: boolean;
  businessRegisterNumber: string;
  defaultDeliveryMethod: "self-shipped" | "external-shipment" | "personal-collection";
  defaultPaymentMethod: OrderPaymentType;
  salesAccount: {
    id: number;
    currency: string;
  } | null;
  priceList: {
    id: number;
    currency: string;
  } | null;
  addresses: AuthenticatedUserCustomerAddress[];
}

interface DashboardOrder {
  id: number;
  signature: string;
  uuid: string;
  status: Status;
  created: string;
  price: {
    amount: number;
    currency: string;
  };
}
export interface DashboardProduct {
  id: number;
  picture: string;
  name: string;
  indexes: Record<string, number>;
}
export interface CustomerDashboard {
  orders: DashboardOrder[];
  products: DashboardProduct[];
}

export interface CustomerEmployee {
  createdAt: ISODateTime;
  email: string;
  id: number;
  user: Assign<UserWithInitialsExtendedDetails, { lastLogin: ISODateTime | null }>;
  customer: number;
}

export interface PostCustomerEmployee {
  avatar: string | null;
  confirmPassword: string;
  customer: number;
  email: string;
  firstName: string;
  isActive: boolean;
  lastName: string;
  password: string;
  phone: string;
}

export interface CustomerLight {
  id: number;
  fullName: string;
  name: string;
  postCode: string;
  street: string;
  city: string;
  hasDropShipping: boolean;
  canAddNewIndexes: boolean;
  email: string;
  phone: string;
  salesAccount: {
    id: number;
    name: string;
    currency: string;
  };
  priceList: { id: number; name: string; currency: CURRENCY_TYPE } | null;
  addresses: Address[];
  defaultCurrency: CURRENCY_TYPE;
}

export interface updateCustomersDTO {
  objects: number[];
  defaultCollectionDateDays?: string;
  labelTemplate?: string | null;
  productSet?: number | null;
  priceList?: number | null;
}

export interface GusDetails {
  taxId: string;
  businessRegisterNumber: string;
  fullName: string;
  city: string;
  postCode: string;
  street: string;
  name: string;
  ownerFirstName: string;
  ownerLastName: string;
  phone: string;
  email: string;
}

export interface DeliveryAddressPayload {
  city: string;
  countryCode: CountryCode | "";
  customer: number;
  externalAddressNumber: string;
  isDefault: boolean;
  name: string;
  phone: string;
  point: { lat: number; lng: number } | undefined;
  postCode: string;
  street: string;
}

export interface PostCustomerDTO {
  businessRegisterNumber: string;
  city: string;
  color: string;
  countryCode: CountryCode | "";
  discount: string;
  email: string;
  fullName: string;
  hasPermissionsToCreateProducts: boolean;
  hasPermissionsToEditWarehouseDeliveryDate: boolean;
  isRecipient: boolean;
  name: string;
  ownerFirstName: string;
  ownerLastName: string;
  phone: string;
  postCode: string;
  salesAccount: number | null;
  street: string;
  taxId: string;
  textColor: string;
  website: string;
}
