import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { EditTradingDocument } from "pages/tradingDocuments/editTradingDocument/EditTradingDocument";
import { Receipts as ReceiptsList } from "pages/tradingDocuments/receiptsList/Receipts";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const Receipts = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={ReceiptsList}
        exact={true}
        path={`${match.path}/list/:tab`}
        title="Milo - paragony"
      />
      <Route
        component={EditTradingDocument}
        exact={true}
        path={`${match.path}/:tradingDocumentId/edit`}
        title="Milo - edytuj paragon"
      />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};
