import { TradingDocument } from "api/trading-documents/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { columnFactory } from "../shared/columnsFactory/columnFactory";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import {
  isDateOlderThan24Hours,
  isDateOlderThan48Hours,
} from "../shared/utils/isDateOlderThan24Hours";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import styles from "../tradingDocumentsList/TradingDocumentsList.module.css";
import { cx } from "utilities";
import { MdiMatch } from "components/miloDesignSystem/atoms/icons/MdiMatch";

export const usePurchaseInvoicesColumns = () => {
  return useCreateTableColumns<TradingDocument>(({ columnHelper }) => {
    const columns = columnFactory(columnHelper);
    return [
      columns.invoiceConfirmation(),
      columnHelper.accessor(row => row, {
        header: "sprzedawca",
        size: 210,
        cell: info => {
          const tradingDocument = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1 justify-content-between w-100">
              <Typography className={styles.invoiceSpacing} fontSize="14" fontWeight="700" noWrap>
                {tradingDocument.sellerCompanyName ||
                  `${tradingDocument.sellerFirstName} ${tradingDocument.sellerLastName}`}
              </Typography>
              {!isDateOlderThan48Hours(tradingDocument.createdAt) && (
                <div
                  className={cx("d-flex align-items-center", {
                    [styles.highOpacity]: isDateOlderThan24Hours(tradingDocument.createdAt),
                  })}
                >
                  <Tag label="Nowa" type="outlined" variant="success" />
                </div>
              )}
            </div>
          );
        },
      }),
      columns.signature(110),
      columnHelper.accessor(row => row.isGrnConfirmed, {
        id: "isGrnConfirmed",
        header: " ",
        size: 30,
        cell: info => {
          const isGrnConfirmed = info.getValue();
          return <MdiMatch color={isGrnConfirmed ? "deepPurple400" : "neutralBlack32"} size="20" />;
        },
      }),
      columns.amount(),
      columns.invoiceIssueDate({ hasHighlight: false }),
      columns.paymentDeadline(),
      columns.paymentStatus(100),
      columns.paymentDeadlineDelta(120),
      columns.assignedTo(),
      columnHelper.text(
        row => row.recipientCompanyName ?? `${row.recipientFirstName} ${row.recipientLastName}`,
        {
          header: "nabywca",
          size: 250,
          typographyProps: {
            className: styles.invoiceSpacing,
            color: "neutralBlack48",
            fontSize: "12",
            fontWeight: "500",
          },
        },
      ),
    ];
  });
};
