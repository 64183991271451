import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery, useStateModal } from "hooks";
import { Button } from "components/common";
import downloadPdfIcon from "assets/images/download_pdf.svg";
import { DOMAIN } from "ENDPOINTS";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { MoreInfoButton } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/MoreInfoButton";
import { CreateCorrectionModalState } from "api/trading-documents/models";
import { CreateCorrectionManuallyModal } from "pages/tradingDocuments/salesInvoicesLIst/rightPanel/components/createCorrectionManuallyModal/CreateCorrectionManuallyModal";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { useDrawer } from "hooks/useDrawer";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { DownloadFakir } from "pages/tradingDocuments/shared/components/DownloadFakir";
import { DownloadRevisor } from "pages/tradingDocuments/shared/components/DownloadRevisor";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Typography } from "components/miloDesignSystem/atoms/typography";

export const PanelHeader = () => {
  const { close } = useDrawer("receipts");
  const { query } = useQuery();
  const { panelId } = query;
  const { data: tradingDocument } = useTradingDocument({ id: panelId });
  const createCorrectionManuallyModal = useStateModal<CreateCorrectionModalState | null>();
  const { isTriggerElementHidden } = useScrollableContext();

  if (!tradingDocument) return null;

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="gap-2">
            <ConfirmCheckbox tradingDocument={tradingDocument} />
            <Typography fontSize="16" fontWeight="700" noWrap>
              Paragon {tradingDocument.signature}
            </Typography>
          </SlidingHeaderWrapper>
        ) : (
          <ConfirmTradingDocument tradingDocument={tradingDocument} />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          <FileDownloadHandler
            factoryFn={() => tradingDocumentFileFactory.tradingDocumentPdf(tradingDocument)}
            type="pdf"
          >
            {({ download, isLoading }) => (
              <Tooltip title="Pobierz PDF z danymi paragonu">
                <IconButton
                  icon={<MdiDownloadPdf size="18" />}
                  isLoading={isLoading}
                  onClick={download}
                  variant="transparent"
                />
              </Tooltip>
            )}
          </FileDownloadHandler>
          <DownloadRevisor tradingDocumentIds={[tradingDocument.id]} />
          <DownloadFakir tradingDocumentIds={[tradingDocument.id]} />
          <Button
            kind="transparent-black"
            size="square-s"
            rel="noopener noreferrer"
            href={`${DOMAIN}${tradingDocument.tradingDocumentPdf?.pdf}`}
            target="_blank"
            as="a"
            title="Podgląd PDF paragonu"
          >
            <div className="btnBase btnBaseSmall">
              <img alt="Pobierz PDF" src={downloadPdfIcon} />
            </div>
          </Button>
          <MoreInfoButton close={close} invoice={tradingDocument} panelId={panelId} />
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      <CreateCorrectionManuallyModal
        close={createCorrectionManuallyModal.close}
        isOpen={createCorrectionManuallyModal.isOpen}
        state={createCorrectionManuallyModal.state}
        type="RECEIPT"
      />
    </>
  );
};
